import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { isAppApiLocal } from '../utils/getAppApiEnv';

export const fetchOrderItemStatuses = createAsyncThunk(
  'orderItemStatuses/fetchOrderItemStatuses',
  async ({ orderId, itemId }, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      if (isAppApiLocal()) {
        const mockedResponse = await client.get("/mocks/orderItemStatuses.json");
        return mockedResponse.data.data;
      }
      const token = getState().auth.accessToken;
      const response = await apiClientService.fetchOrderItemStatuses(orderId, itemId, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch order item statuses");
    }
  },
);

export const addOrderItemStatus = createAsyncThunk(
  'orderItemStatuses/addOrderItemStatus',
  async ({ orderId, itemId, statusId }, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.addOrderItemStatus(orderId, itemId, statusId, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to add order item status");
    }
  },
);

export const orderItemStatusesSlice = createSlice({
  name: 'orderItemStatuses',
  initialState: {
    orderItemStatuses: [],
    selectedItemId: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    clear: (state) => {
      state.orderItemStatuses = [];
      state.status = 'idle';
      state.selectedItemId = null;
      state.error = null;
    },
    setSelectedItem: (state, action) => {
      state.selectedItemId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrderItemStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderItemStatuses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orderItemStatuses = action.payload || [];
      })
      .addCase(fetchOrderItemStatuses.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      })
      .addCase(addOrderItemStatus.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      });
  },
});

export const getOrderItemStatuses = (state) => state.orderItemStatuses.orderItemStatuses || [];
export const getSelectedItemId = (state) => state.orderItemStatuses.selectedItemId;
export const getOrderItemStatusesError = (state) => state.orderItemStatuses.error;

export const { clear, setSelectedItem } = orderItemStatusesSlice.actions;
