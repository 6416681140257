import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { isAppApiLocal } from '../utils/getAppApiEnv';

export const fetchOrder = createAsyncThunk(
  'order/fetchOrder',
  async (id, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      if (isAppApiLocal()) {
        const mockedResponse = await client.get("/mocks/order.json");
        return mockedResponse.data;
      }
      const token = getState().auth.accessToken;
      const response = await apiClientService.fetchOrderById(id, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch order");
    }
  },
);

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    currentOrder: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    clear: (state) => {
      state.currentOrder = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentOrder = action.payload || [];
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      });
  },
});

export const selectCurrentOrder = (state) => state.order.currentOrder || null;
export const getCurrentOrderStatus = (state) => state.order.status;
export const getOrderError = (state) => state.order.error;

export const { clear } = orderSlice.actions;
