import useApiClient from "../hooks/useApiClient";

const createApiClientService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const apiClient = useApiClient();

  return {
    authenticateUser: async (data) => apiClient.post("/auth", data),
    postProductToChannel: async (productId, token) => apiClient.post(`/channels/products/${productId}`, null, token),
    fetchOrderItemStatuses: async (orderId, itemId, token) => apiClient.get(`/orders/${orderId}/items/${itemId}/statuses`, token),
    addOrderItemStatus: async (orderId, itemId, statusId, token) => apiClient.post(`/orders/${orderId}/items/${itemId}/statuses/${statusId}`, null, token),
    fetchOrderById: async (id, token) => apiClient.get(`/orders/${id}`, token),
    fetchOrders: async (shopId, token) => {
        const url = shopId ? `/orders?shopId=${shopId}` : "/orders"; 
        return apiClient.get(url, token);
    },
    fetchAvailableOrderStatuses: async (token) => apiClient.get("/orders/statuses", token),
    fetchProductById: async (id, token) => apiClient.get(`/products/${id}`, token),
    deleteProduct: async (id, token) => apiClient.delete(`/products/${id}`, token),
    fetchProducts: async (shopId, token) => {
      const url = shopId ? `/products?shopId=${shopId}` : "/products";
      return apiClient.get(url, token);
    },
  };
};

export default createApiClientService;
