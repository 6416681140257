/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { isAppApiLocal } from '../utils/getAppApiEnv';

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (shopId, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      if (isAppApiLocal()) {
        const mockedResponse = await client.get("/mocks/products.json");
        return mockedResponse.data.data;
      }
      const token = getState().auth.accessToken;
      const response = await apiClientService.fetchProducts(shopId, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch products");
    }
  },
);

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    load: (state) => {
      state.products = [];
    },
    clearProducts: (state) => {
      state.products = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload || [];
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      });
  },
});

export const selectAllProducts = (state) => state.products.products || [];
export const getProductsStatus = (state) => state.products.status;
export const getProductsError = (state) => state.products.error;

export const { load, clearProducts } = productsSlice.actions;
