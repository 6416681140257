import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { isAppApiLocal } from '../utils/getAppApiEnv';

export const fetchOrderStatuses = createAsyncThunk(
  'orderStatuses/fetchOrderStatuses',
  async (_, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try{
      if (isAppApiLocal()) {
        const mockedResponse = await client.get('/mocks/orderStatuses.json');
        return mockedResponse.data.data;
      }
      const token = getState().auth.accessToken;
      const response = await apiClientService.fetchAvailableOrderStatuses(token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch available order statuses");
    }
  },
);

export const orderStatusesSlice = createSlice({
  name: 'orderStatuses',
  initialState: {
    orderStatuses: [],
    status: 'idle',
    selectedStatus: null,
    error: null,
  },
  reducers: {
    clear: (state) => {
      state.orderStatuses = [];
      state.status = 'idle';
      state.error = null;
    },
    load: (state) => {
      state.orderStatuses = [];
    },
    setSelectedStatus: (state, action) => {
      state.selectedStatus = action.payload;
    },
    clearSelectedStatus: (state) => {
      state.selectedStatus = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrderStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderStatuses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orderStatuses = action.payload || [];
      })
      .addCase(fetchOrderStatuses.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      })
  },
});

export const getOrderStatuses = (state) => state.orderStatuses.orderStatuses || [];
export const getOrderStatusesStatus = (state) => state.orderStatuses.status;
export const getOrderStatusesError = (state) => state.orderStatuses.error;
export const getSelectedStatus = (state) => state.orderStatuses.selectedStatus;

export const { load, clear, setSelectedStatus, clearSelectedStatus } = orderStatusesSlice.actions;
