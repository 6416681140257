import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import getAllErrors from "../../redux/selectors";

function ErrorAlert() {
  const error = useSelector(getAllErrors);
  const [open, setOpen] = useState(!!error);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setOpen(true);
      setVisible(true);

      // Set a timer to auto-close the alert after 5 seconds
      const timer = setTimeout(() => {
        setVisible(false); // Start fade-out
        const closeTimer = setTimeout(() => {
          setOpen(false); // Close alert after fade-out
        }, 500); // Match this duration with CSS transition time
        return () => clearTimeout(closeTimer);
      }, 5000); // Keep the alert visible for 5 seconds

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [error]);

  if (!open || !error) return null;

  return (
    <div style={{
      position: "fixed",
      bottom: "100px",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 1000,
      borderRadius: "8px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      transition: "opacity 0.5s ease", // Transition for opacity
      opacity: visible ? 1 : 0, // Control opacity based on visibility state
      minWidth: "300px", // Minimum width to prevent wrapping
      maxWidth: "90%", // Max width to ensure it doesn't go off the screen
    }}
    >
      <Alert
        severity="error"
        variant="filled"
        onClose={() => {
          setVisible(false); // Start fade-out
          const closeTimer = setTimeout(() => {
            setOpen(false); // Close alert after fade-out
          }, 500); // Match this duration with CSS transition time
          return () => clearTimeout(closeTimer);
        }}
      >
        {error}
      </Alert>
    </div>
  );
}

export default ErrorAlert;
