// OrderDetails.jsx
import React, { useEffect, useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  // StepContent,
  // StepIcon,
  Button,
  SwipeableDrawer,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosRounded';
import PersonIcon from '@mui/icons-material/PersonOutlineRounded';
import LocationIcon from '@mui/icons-material/LocationOnRounded';
import WalletIcon from '@mui/icons-material/AccountBalanceWalletRounded';
// import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { styled } from '@mui/material/styles';
import {
  selectCurrentOrder,
  getCurrentOrderStatus,
  fetchOrder,
  clear as clearOrder,
} from '../../redux/orderSlice';
import { 
  getOrderStatuses,
  getOrderStatusesStatus,
  fetchOrderStatuses,
  getSelectedStatus,
  setSelectedStatus,
  clearSelectedStatus,
} from '../../redux/orderStatusesSlice';
import {
  fetchOrderItemStatuses,
  getOrderItemStatuses,
  getSelectedItemId,
  setSelectedItem,
  clear as clearStatuses,
  addOrderItemStatus
} from '../../redux/orderItemStatusesSlice';
import getFormattedDate from '../../utils/getFormattedDate';
import getFormattedDateTime from '../../utils/getFormattedDateTime';
import getProductAmountLabel from '../../utils/getProductAmountLabel';
import useQueryParams from '../../hooks/useQueryParams';
import styles from './OrderDetails.module.css';
import OrderDetailsItem from './OrderDetailsItem';
import OrderDetailsSkeleton from './OrderDetailsSkeleton';

function OrderDetails() {
  const { id } = useParams();
  const order = useSelector(selectCurrentOrder);
  const status = useSelector(getCurrentOrderStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemsLabel = getProductAmountLabel(order?.items.length);
  const { shopId } = useQueryParams();
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [selectedStatus, setSelectedStatus] = useState(null);
  const [drawerHeight, setDrawerHeight] = useState(null);
  const contentRef = useRef(null);
  const orderItemStatuses = useSelector(getOrderItemStatuses);
  const orderStatuses = useSelector(getOrderStatuses);
  const orderStatusesStatus = useSelector(getOrderStatusesStatus);
  const selectedStatus = useSelector(getSelectedStatus);
  const selectedItemId = useSelector(getSelectedItemId);

  const ordersPath = shopId 
    ? `/orders?shopId=${shopId}` 
    : `/orders`;

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    dispatch(clearSelectedStatus());
  };

  const handleStatusChange = (status) => {
    dispatch(setSelectedStatus(status));
  };

  const handleConfirmation = async () => {
    await dispatch(addOrderItemStatus({
      orderId: order.id,
      itemId: selectedItemId,
      statusId: selectedStatus.id,
    }));
    await dispatch(fetchOrderItemStatuses({
      orderId: order.id,
      itemId: selectedItemId,
    }));
    handleCloseDrawer();
  };

  const handleCancel = () => {
    dispatch(clearSelectedStatus());
  }

  const navigateToOrders = () => {
    navigate(ordersPath);
  };

  useEffect(() => {
    if (contentRef.current && !drawerHeight) {
      setDrawerHeight(contentRef.current.clientHeight);
    }
  }, [drawerHeight, drawerOpen]);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchOrder(id));
    }
  }, [status, id, dispatch]);

  useEffect(() => {
    if (order && !selectedItemId) {
      dispatch(setSelectedItem(order.items[0].itemId));
      dispatch(fetchOrderItemStatuses({
        orderId: order.id,
        itemId: order.items[0].itemId,
       }));
    }
  }, [selectedItemId, order, dispatch]);
  
  useEffect(() => {
    if (orderStatusesStatus === 'idle') {
      dispatch(fetchOrderStatuses());
    }
  }, [orderStatusesStatus, orderStatuses, id, dispatch]);

  useEffect(() => (() => {
    dispatch(clearOrder());
    dispatch(clearStatuses());
  }), [dispatch, id]);

  const Puller = styled(Box)(() => ({
    width: 40,
    height: 6,
    backgroundColor: 'var(--tg-theme-hint-color)',
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 20px)',
  }));

  if (status === 'loading') {
    return <OrderDetailsSkeleton />;
  }

  if (!order) {
    return (
      <div className={styles.orderDetailsContainer}>
        <AppBar
          position="fixed"
          sx={{ backgroundColor: 'var(--tg-theme-section-bg-color)', boxShadow: 'none', height: '56px' }}
        >
          <Toolbar sx={{ height: '56px', display: 'flex', justifyContent: 'space-between' }}>
            <IconButton onClick={navigateToOrders}>
              <ArrowBackIosIcon className={styles.headerIcon} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Typography variant="h6" sx={{ margin: '0px', padding: '150px 0px', color: 'var(--tg-theme-destructive-text-color)' }}>Order not found</Typography>
      </div>
    );
  }

  return (
    <div className={styles.orderDetailsContainer}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: 'var(--tg-theme-section-bg-color)', boxShadow: 'none', height: '56px' }}
      >
        <Toolbar sx={{ height: '56px', display: 'flex', justifyContent: 'space-between' }}>
          <IconButton onClick={navigateToOrders}>
            <ArrowBackIosIcon className={styles.headerIcon} />
          </IconButton>
          <div className={styles.headerText}>
            <Typography variant="h6" sx={{ color: 'var(--tg-theme-text-color)' }}>
              {`Order ${order.id}`}
            </Typography>
            <Typography variant="caption" sx={{ color: 'var(--tg-theme-hint-color)' }}>
              {`from ${getFormattedDate(order.createdAt)}`}
            </Typography>
          </div>
          <div className={styles.emptyDiv} />
        </Toolbar>
      </AppBar>
      <div className={styles.orderDetails}>
        <Typography variant="h5" sx={{ color: 'var(--tg-theme-subtitle-text-color)' }}>
          {order.status}
        </Typography>
        <div className={styles.recieverSection}>
          <div className={styles.shippingIconContainer}>
            <LocationIcon className={styles.icon} />
          </div>
          <div className={styles.shippingInfoContainer}>
            <Typography variant="caption" sx={{ color: 'var(--tg-theme-hint-color)' }}>
              Delivery
            </Typography>
            <Typography variant="body2" sx={{ color: 'var(--tg-theme-text-color)' }}>
              {order.shippingAddress1}
            </Typography>
            <Typography variant="caption" sx={{ color: 'var(--tg-theme-hint-color)' }}>
              {order.shippingAddress2 ?? ''}
            </Typography>
          </div>
        </div>
        <div className={styles.shippingSection}>
          <div className={styles.shippingIconContainer}>
            <PersonIcon className={styles.icon} />
          </div>
          <div className={styles.shippingInfoContainer}>
            <Typography variant="caption" sx={{ color: 'var(--tg-theme-hint-color)' }}>
              Recipient
            </Typography>
            <Typography variant="body2" sx={{ color: 'var(--tg-theme-text-color)' }}>
              {order.receiverFullName}
            </Typography>
            <Typography variant="caption" sx={{ color: 'var(--tg-theme-hint-color)' }}>
              {order.receiverPhone}
            </Typography>
          </div>
        </div>
        <div className={styles.totalSection}>
          <div className={styles.shippingIconContainer}>
            <WalletIcon className={styles.icon} />
          </div>
          <div className={styles.totalContainer}>
            <div>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '20px',
                  textAlign: 'left',
                  color: 'var(--tg-theme-text-color)',
                }}
              >
                Total
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'left',
                  color: 'var(--tg-theme-text-color)',
                }}
              >
                {itemsLabel}
              </Typography>
            </div>
            <div>
              <Typography
                variant="h5"
                sx={{
                  marginRight: '16px',
                  color: 'var(--tg-theme-text-color)',
                }}
              >
                {`${order.totalPrice} ${order.currencyCode}`}
              </Typography>
            </div>
          </div>
        </div>
        <Typography variant="h6" sx={{ color: 'var(--tg-theme-subtitle-text-color)' }}>
          Order composition
        </Typography>
        <div className={styles.orderDetailsItems}>
          {(order.items || []).map((item) => (
            <OrderDetailsItem
              key={item.itemId}
              item={item}
              orderId={order.id}
            />
          ))}
        </div>
        {/* Stepper to display order status history */}
        <div className={styles.stepperContainer}>
          <Stepper
            activeStep={orderItemStatuses.length}
            orientation="vertical"
          >
            {orderItemStatuses.map((step) => (
              <Step key={step.id} active>
                <StepLabel
                  StepIconProps={{
                    style: {
                      paddingLeft: '4px',
                      fontSize: 'large',
                      color: 'var(--tg-theme-hint-color)',
                      marginRight: '32px',
                    },
                  }}
                >
                  <Typography variant="body2" sx={{ color: 'var(--tg-theme-text-color)' }}>{step.statusName}</Typography>
                  <Typography variant="body2" sx={{ color: 'var(--tg-theme-hint-color)' }}>{getFormattedDateTime(step.createdAt)}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {/* Button to change status */}
        { orderItemStatuses && orderItemStatuses.length > 0 ? (
          <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            onClick={handleOpenDrawer}
            disableElevation
            sx={{
              height: '50px',
              width: '95%',
              borderRadius: '25px',
              backgroundColor: 'var(--tg-theme-button-color)',
              color: 'var(--tg-theme-button-text-color)',
            }}
          >
            Change Status
          </Button>
          </div>
        ) : (
          <div/>
        )}
        {/* Swipeable Drawer for status selection */}
        <SwipeableDrawer
          anchor="bottom"
          open={drawerOpen}
          onClose={handleCloseDrawer}
          onOpen={handleOpenDrawer}
          PaperProps={{
            sx: {
              borderTopLeftRadius: 26,
              borderTopRightRadius: 26,
              backgroundColor: 'var(--tg-theme-secondary-bg-color)',
              height: drawerHeight && selectedStatus ? `${drawerHeight}px` : 'auto',
            },
          }}
        >
          <Puller />
          <div
            ref={contentRef} 
            style={{
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              height: drawerHeight && selectedStatus ? `${drawerHeight}px` : 'auto',
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ paddingBottom: '10px', color: 'var(--tg-theme-section-header-text-color)' }}
            >
              Select new status
            </Typography>
            {
              selectedStatus ? (
                <div>
                  <div className={styles.confirmationContainer}>
                    <Typography variant="body1" sx={{ paddingBottom: '10px', color: 'var(--tg-theme-text-color)' }}>
                      Are you sure you want to proceed with setting the status to:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ paddingBottom: '10px', fontWeight: 'bold', color: 'var(--tg-theme-subtitle-text-color)', textAlign: 'center' }}>
                      {selectedStatus.name}
                    </Typography>
                  </div>
                  <div className={styles.buttonsContainer}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      disableElevation
                      sx={{
                        height: '42px',
                        borderRadius: '21px',
                        minWidth: '48%',
                        backgroundColor: '#b30c04',
                        color: 'var(--tg-theme-button-text-color)',
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleConfirmation}
                      disableElevation
                      sx={{
                        height: '42px',
                        borderRadius: '21px',
                        minWidth: '48%',
                        backgroundColor: 'var(--tg-theme-button-color)',
                        color: 'var(--tg-theme-button-text-color)',
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              ) : (
                <List sx={{ width: '95%' }}>
                {orderStatuses.map((status) => (
                  <ListItem
                    key={status.id}
                    onClick={() => { handleStatusChange(status); }}
                    style={{ color: 'var(--tg-theme-text-color)', cursor: 'pointer' }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      borderRadius: '8px',
                      color: 'var(--tg-theme-text-color)',
                      '&:focus, &:active': {
                        filter: 'brightness(0.9)',
                        backgroundColor: 'var(--tg-theme-section-bg-color)',
                        boxShadow: '0px 3px 5px rgba(0,0,0,0.2)',
                      },
                    }}
                  >
                    <ListItemText primary={status.name} primaryTypographyProps={{ sx: { variant: 'body1', textAlign: 'center' } }} />
                  </ListItem>
                ))}
              </List>
              )
            }
          </div>
        </SwipeableDrawer>
      </div>
    </div>
  );
}

export default OrderDetails;
