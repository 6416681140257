import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useQueryParams from '../../hooks/useQueryParams';

function RedirectToPage() {
  const navigate = useNavigate();
  const { shopId } = useQueryParams();

  const productsPath = shopId 
    ? `/products?shopId=${shopId}`
    : `/products`;
  
  useEffect(() => {
    navigate(productsPath, { replace: true });
  }, [navigate, productsPath]);

  return null;
}

export default RedirectToPage;