import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const postProductToChannel = createAsyncThunk(
  'channels/postProductToChannel',
  async (productId, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.postProductToChannel(productId, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to post product to channel");
    }
  },
);

const initialState = {
  postProductStatus: 'idle',
  error: null,
};

export const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    resetPostProductStatus: (state) => {
      state.postProductStatus = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postProductToChannel.pending, (state) => {
        state.postProductStatus = 'loading';
        state.error = null;
      })
      .addCase(postProductToChannel.fulfilled, (state) => {
        state.postProductStatus = 'succeeded';
        state.error = null;
      })
      .addCase(postProductToChannel.rejected, (state, action) => {
        state.postProductStatus = 'failed';
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      });
  },
});

export const { load, resetPostProductStatus } = channelSlice.actions;
export const getPostProductStatus = (state) => state.channel.postProductStatus;
export const getPostProductError = (state) => state.channel.error;

export default channelSlice.reducer;
