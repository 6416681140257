import { createSelector } from "@reduxjs/toolkit";
import { getAuthError } from "./authSlice";
import { getPostProductError } from "./channelSlice";
import { getOrderItemStatusesError } from "./orderItemStatusesSlice";
import { getOrderError } from "./orderSlice";
import { getOrdersError } from "./ordersSlice";
import { getOrderStatusesError } from "./orderStatusesSlice";
import { getProductError } from "./productSlice";
import { getProductsError } from "./productsSlice";

// Combine all errors from different slices
const getAllErrors = createSelector(
  [getAuthError, getPostProductError, getOrderItemStatusesError, getOrderError,
    getOrdersError, getOrderStatusesError, getProductError, getProductsError ],
  (authError, postProductError, orderItemStatusesError, orderError, ordersError, orderStatusesError, productError, productsError) => authError
    || postProductError || orderItemStatusesError || orderError || ordersError
    || orderStatusesError || productError || productsError,
);

export default getAllErrors;
