import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { isAppApiLocal } from '../utils/getAppApiEnv';

export const fetchProduct = createAsyncThunk(
  'products/fetchProduct',
  async (id, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      if (isAppApiLocal()) {
        const mockedResponse = await client.get("/mocks/product.json");
        return mockedResponse.data;
      }
      const token = getState().auth.accessToken;
      const response = await apiClientService.fetchProductById(id, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch product");
    }
  },
);

export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (id, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.deleteProduct(id, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to delete product");
    }
  },
);

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    currentProduct: null,
    status: 'idle',
    error: null,
    deleteProductStatus: 'idle',
    deleteProductError: null,
  },
  reducers: {
    clear: (state) => {
      state.currentProduct = null;
      state.status = 'idle';
      state.error = null;
    },
    resetDeleteProductStatus: (state) => {
      state.deleteProductStatus = 'idle';
      state.deleteProductError = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentProduct = action.payload || [];
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      })
      .addCase(deleteProduct.pending, (state) => {
        state.deleteProductStatus = 'loading';
      })
      .addCase(deleteProduct.fulfilled, (state) => {
        state.deleteProductStatus = 'succeeded';
        state.currentProduct = null;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.deleteProductStatus = 'failed';
        if (action.payload?.errors) {
          state.deleteProductError = action.payload.errors[0].title;
        } else {
          state.deleteProductError = action.payload || "An error occurred.";
        }
      });
  },
});

export const selectCurrentProduct = (state) => state.product.currentProduct || null;
export const getCurrentProductStatus = (state) => state.product.status;
export const getDeleteProductStatus = (state) => state.product.deleteProductStatus;
export const getProductError = (state) => state.product.error || state.product.deleteProductError || null;

export const { clear, resetDeleteProductStatus } = productSlice.actions;
