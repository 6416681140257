import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrders, selectOrders, getOrdersStatus } from '../../redux/ordersSlice';
import OrderCard from './OrderCard';
import OrderListSkeleton from './OrderListSkeleton';
import styles from './OrderList.module.css';
import useQueryParams from '../../hooks/useQueryParams';

function OrderList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orders = useSelector(selectOrders);
  const ordersStatus = useSelector(getOrdersStatus);
  const { shopId } = useQueryParams();
  const productsPath = shopId 
    ? `/products?shopId=${shopId}`
    : `/products`;

  useEffect(() => {
    if (ordersStatus === 'idle') {
      dispatch(fetchOrders(shopId));
    }
  }, [ordersStatus, dispatch, shopId]);

  const navigateToProducts = () => {
    navigate(productsPath);
  };

  if (ordersStatus === 'loading') {
    return <OrderListSkeleton />;
  }

  if (!orders || !orders.length) {
    return (
      <div className={styles.orderDetailsContainer}>
        <AppBar
          position="fixed"
          sx={{ backgroundColor: 'var(--tg-theme-section-bg-color)', boxShadow: 'none', height: '56px' }}
        >
          <Toolbar sx={{ height: '56px' }}>
            <IconButton onClick={navigateToProducts}>
              <ArrowBackIosIcon className={styles.headerIcon} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Typography variant="h6" sx={{ margin: '0px', padding: '150px 0px', color: 'var(--tg-theme-destructive-text-color)' }}>Orders not found</Typography>
      </div>
    );
  }

  return (
    <div className={styles.orderListContainer}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: 'var(--tg-theme-section-bg-color)', boxShadow: 'none', height: '56px' }}
      >
        <Toolbar sx={{ height: '56px' }}>
          <IconButton onClick={navigateToProducts}>
            <ArrowBackIosIcon className={styles.headerIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.orderList}>
        {orders.map((order) => (
          <OrderCard key={order.id} order={order} />
        ))}
      </div>
    </div>
  );
}

export default OrderList;
