import React from 'react';
import PropTypes from 'prop-types';
import { noImageURL } from '../../api/config';
import styles from './OrderCardItem.module.css';

function OrderCardItem({ orderItemProduct }) {

  return (
    <div className={styles.cardItem}>
      <img src={orderItemProduct.mainMediaUrl || noImageURL} alt={orderItemProduct.title} />
    </div>
  );
}

OrderCardItem.propTypes = {
  orderItemProduct: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mainMediaUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrderCardItem;
