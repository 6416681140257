import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from '../redux/authSlice';
import { productsSlice } from '../redux/productsSlice';
import { productSlice } from '../redux/productSlice';
import { ordersSlice } from '../redux/ordersSlice';
import { orderSlice } from '../redux/orderSlice';
import { channelSlice } from '../redux/channelSlice';
import { orderItemStatusesSlice } from '../redux/orderItemStatusesSlice';
import { orderStatusesSlice } from '../redux/orderStatusesSlice';
import createApiClientService from "../api/apiService";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    products: productsSlice.reducer,
    product: productSlice.reducer,
    channel: channelSlice.reducer,
    orders: ordersSlice.reducer,
    order: orderSlice.reducer,
    orderItemStatuses: orderItemStatusesSlice.reducer,
    orderStatuses: orderStatusesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: {
        apiClientService: createApiClientService(),
      },
    },
  }),
});

export default store;
