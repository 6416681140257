const getFormattedDateTime = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the time with leading zeroes if necessary
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  // Format the date
  const formattedDate = `${formattedTime} ${day} ${month} ${year}`;
  return formattedDate;
};

export default getFormattedDateTime;
