import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Typography,
} from '@mui/material';
import styles from './OrderCard.module.css';
import OrderCardItem from './OrderCardItem';
import useQueryParams from '../../hooks/useQueryParams';

function OrderCard({ order }) {
  const { shopId } = useQueryParams();

  const orderPath = shopId 
    ? `/orders/${order.id}?shopId=${shopId}`
    : `/orders/${order.id}`;
    
  return (
    <Link to={orderPath} className={styles.link}>
      <div className={styles.orderItem}>
        <div className={styles.orderDetails}>
          <Typography variant="h5" sx={{ padding: '10px', fontWeight: 'bold', color: 'var(--tg-theme-subtitle-text-color)' }}>
            {order.status}
          </Typography>
          <div className={styles.cardItems}>
            {order.items.map((item) => (
              <OrderCardItem key={item.id} orderItemProduct={item} />
            ))}
          </div>
          <Typography variant="body1" sx={{ color: 'var(--tg-theme-text-color)' }}>
            {`${order.items.length} Items · ${order.totalPrice} ${order.currencyCode}`}
          </Typography>
        </div>
      </div>
    </Link>
  );
}

OrderCard.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    currencyCode: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired,
    items: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
};

export default OrderCard;
